<template>
  <div v-if="this.$route.name == 'Admin_Tests'">
    <Navigation
      v-bind:Breadcrumb="[
        { name: 'Home', route: 'Home' },
        { name: 'Staff', route: 'Admin' },
        { name: 'View Tests', route: 'Admin_Tests' },
      ]"
    />
    <div class="placeholder">
      <Admin_Tests_ListDatatable />
    </div>
  </div>
  <div v-else>
    <router-view />
  </div>
</template>

<script>
import Navigation from "@/components/Navigation.vue";
import Admin_Tests_ListDatatable from "@/components/Admin.Tests.ListDatatable.vue";

export default {
  name: "Admin_Tests",
  components: {
    Navigation,
    Admin_Tests_ListDatatable,
  },
};
</script>

<style>
</style>